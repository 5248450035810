<template>
  <div>
    <div class="table-button">
      <Button type="primary" @click="toAdd()">新增</Button>
    </div>
    <div class="table-input">
      <Input v-model="param.name" clearable placeholder="主题,商家名称" />
      <Button type="primary" @click="search()">搜索</Button>
    </div>
    <Table :columns="columns" :data="data" @on-selection-change="changeSelection">

      <template slot-scope="{ row, index }" slot="headUrl">
        <img :src="item" style="width: 30px;height: 30px;margin:5px 5px 0 5px;cursor: zoom-in;" v-for="item,index in row.headUrlArr" :key="index" @click="MyUtil.showBigImg(item)" />
      </template>

      <template slot-scope="{ row, index }" slot="type">
        <span v-if="row.type==1">平台</span>
        <span v-if="row.type==2">商家</span>
      </template>

      <template slot-scope="{ row, index }" slot="status">
        <span v-if="row.status==1" style="color:green">启用</span>
        <span v-if="row.status==0" style="color:red">禁用</span>
      </template>

      <template slot-scope="{ row, index }" slot="action">
        <Button v-if="row.status==1" type="primary" size="small" @click="toStatus(row)">禁用</Button>
        <Button v-if="row.status==0" type="primary" size="small" @click="toStatus(row)">启用</Button>
        <Button type="primary" size="small" @click="toUpdate(row)">修改</Button>
        <Button type="primary" size="small" @click="toDelete(row)">删除</Button>
      </template>
    </Table>
    <Page :total="total" :current="param.page" :page-size="param.rows" show-total show-sizer @on-change="changePage" @on-page-size-change="changePageSize"></Page>

    <!-- 模态框 -->
    <Modal v-model="modal" :title="modalTitle" width="600">
      <Form ref="form" :model="form" :rules="formRules" :label-width="80">
        <FormItem label="主题：" prop="title" style="width: 80%">
          <Input v-model="form.title"></Input>
        </FormItem>

        <FormItem label="图片：" prop="url">
          <!-- 上传按钮 -->
          <Upload v-model="form.url" :show-upload-list="false" :default-file-list="uploadList" :action="uploadUrl" :data="uploadData" :max-size="10240" :on-exceeded-size="()=> this.$Message.warning('文件大小不能超过10M')"
            :format="['jpg','jpeg','png']" :on-format-error="()=> this.$Message.warning('文件格式支持jpg,jpeg,png')" :before-upload="handleBeforeUpload" :on-progress="handleOnProgress" :on-success="handleOnSuccess">
            <Button :disabled="(uploadPercent>0&&uploadPercent<100) || uploadList.length>=9">上传头图</Button><span style="font-size:12px;color:#999999">最多上传9张图片</span>
          </Upload>
          <!-- 上传预览 -->
          <div v-for="item,index in uploadList" style="display: inline-block;">
            <img style="width: 100px;height: 100px;margin:10px 0 0 0;cursor: zoom-in;" :src="item.url" @click="MyUtil.showBigImg(item.url)">
            <Icon type="ios-close" style="margin:10px 10px 0 -14px;cursor: pointer;vertical-align: top;background-color:#999999;color:#ffffff" @click="handleOnRemove(index)">
            </Icon>
          </div>
          <!-- 进度条 -->
          <Progress v-if="uploadPercent>0&&uploadPercent<100" :percent="uploadPercent" style="width: 100px;" :stroke-width="5"></Progress>
        </FormItem>

        <FormItem label="状态：" prop="status">
          <RadioGroup v-model="form.status" style="margin-top:8px">
            <Radio :label="1">
              启用
            </Radio>
            <Radio :label="0">
              禁用
            </Radio>
          </RadioGroup>
        </FormItem>

        <FormItem label="适用：" prop="type">
          <RadioGroup v-model="form.type" style="margin-top:8px">
            <Radio :label="1">
              平台
            </Radio>
            <Radio :label="2">
              用户
            </Radio>
          </RadioGroup>
        </FormItem>

        <FormItem label="用户：" prop="userId" v-if="form.type==2">
          <Select v-model="form.userId" filterable :remote-method="getUserList" :loading="optionsLoading" clearable>
            <Option v-for="(item, index) in options" :value="item.id" :key="index">{{item.nickname+'('+item.phone+')'}}</Option>
          </Select>
        </FormItem>

      </Form>
      <div slot="footer">
        <Button @click="value=>this.modal=false">关闭</Button>
        <Button type="primary" @click="save()">确定</Button>
      </div>
    </Modal>

  </div>
</template>

<script>
export default {
  data() {
    return {
      MyUtil: this.MyUtil,
      //列表
      deleteIds: [],
      param: {
        name: null,
        page: 1,
        rows: 10,
      },
      total: 0,
      data: [],
      columns: [
        {
          type: "selection",
          width: 35,
        },
        {
          title: "主题",
          key: "title",
          ellipsis: true,
          tooltip: true,
        },
        {
          title: "头图",
          slot: "headUrl",
          minWidth: 300,
        },
        {
          title: "头图类型",
          slot: "type",
          width: 100,
        },
        {
          title: "商家名称",
          key: "nickname",
          width: 200,
          ellipsis: true,
          tooltip: true,
        },
        {
          title: "商家电话",
          key: "phone",
          width: 150,
        },
        {
          title: "状态",
          slot: "status",
          width: 100,
        },
        {
          title: "操作",
          slot: "action",
          fixed: "right",
          width: 180,
        },
      ],

      //模态框
      modal: false,
      modalTitle: "",
      options: [],
      optionsLoading: false,
      form: {
        title: null,
        url: null,
        status: 1,
        type: 1,
        userId: null,
      },
      formRules: {
        title: [
          {
            required: true,
            message: "此项不能为空",
          },
        ],
        url: [
          {
            required: true,
            message: "此项不能为空",
            trigger: "change",
          },
        ],
        userId: [
          {
            required: true,
            message: "此项不能为空",
            type: "number",
            trigger: "change",
          },
        ],
      },
      //上传
      uploadPercent: 0, //上传百分比
      uploadUrl: "", //上传地址
      uploadDir: "headurl", //存储位置
      uploadData: {}, //上传参数
      uploadList: [], //已上传文件列表
    };
  },
  methods: {
    getDataList() {
      this.MyUtil.ajax()
        .post("/HeadUrlController/getHeadUrlList", this.param)
        .then((resData) => {
          this.data = resData.data;
          this.total = resData.total;
          if (this.data) {
            this.data.forEach((element) => {
              if (element.url) {
                element.headUrlArr = element.url.split(",");
              }
            });
          }
        });
    },
    changePage(page) {
      this.param.page = page;
      this.getDataList();
    },
    changePageSize(rows) {
      this.param.rows = rows;
      this.getDataList();
    },
    changeSelection(selection) {
      this.deleteIds = [];
      selection.forEach((element) => {
        this.deleteIds.push(element.id);
      });
    },
    search() {
      this.param.page = 1;
      this.getDataList();
    },

    toAdd(row) {
      this.$router.push({
        name: "headUrlAdd",
      });
    },

    toUpdate(row) {
      this.$router.push({
        name: "headUrlUpdate",
        params: {
          row: row,
        },
      });
    },

    toStatus(row) {
      this.$Modal.confirm({
        title: "操作确认",
        content: "点击确认，以继续执行该操作！",
        onOk: () => {
          this.MyUtil.ajax()
            .post("/HeadUrlController/updateHeadUrlStatus", {
              id: row.id,
            })
            .then((resData) => {
              this.$Message.success("操作成功");
              this.getDataList();
            });
        },
      });
    },

    toDelete(row) {
      let ids = [];
      if (row) {
        ids.push(row.id);
      } else {
        if (this.deleteIds.length < 1) {
          this.$Message.warning("请至少选择一条数据");
          return;
        }
        ids = this.deleteIds;
      }
      this.$Modal.confirm({
        title: "操作确认",
        content: "点击确认，以继续执行该操作！",
        onOk: () => {
          this.MyUtil.ajax()
            .post("/HeadUrlController/deleteHeadUrl", {
              deleteIds: ids,
            })
            .then((resData) => {
              this.$Message.success("删除成功");
              this.getDataList();
              this.deleteIds = [];
            });
        },
      });
    },

    handleBeforeUpload(file) {
      let fileName = file.name;
      let fileSuffix = fileName.substring(
        fileName.lastIndexOf("."),
        fileName.length
      );
      //获取oss文件上传授权
      return new Promise((resolve, reject) => {
        this.MyUtil.ajax()
          .post("/FileController/getOssAuthorize", {
            dir: this.uploadDir,
          })
          .then((resData) => {
            //设置上传参数
            this.uploadData = resData;
            //设置上传地址
            this.uploadUrl = resData.host;
            //设置存储路径
            this.uploadData.key =
              this.uploadDir + "/" + this.MyUtil.uuid() + fileSuffix;
            resolve(true);
          });
      });
    },
    handleOnProgress(event) {
      //设置上传进度
      if (event.percent) {
        this.uploadPercent = event.percent;
      }
    },
    handleOnSuccess(response, file) {
      //设置已上传文件
      this.uploadList.push({
        name: file.name,
        url: this.MyUtil.fileUrl + this.uploadData.key,
      });
      let urlArr = [];
      this.uploadList.forEach((element) => {
        urlArr.push(element.url);
      });
      this.form.url = urlArr.join(";");
    },

    handleOnRemove(index) {
      this.uploadList.splice(index, 1);
      let urlArr = [];
      this.uploadList.forEach((element) => {
        urlArr.push(element.url);
      });
      this.form.url = urlArr.join(";");
    },

    getUserList(query) {
      if (this.MyUtil.isNull(query)) {
        this.options = [];
        return;
      }
      if (this.optionsLoading == false) {
        this.optionsLoading = true;
        setTimeout(() => {
          this.MyUtil.ajax()
            .post("/UserController/getUserList", { name: query })
            .then((resData) => {
              this.options = resData.data;
              this.optionsLoading = false;
              if (this.options) {
                this.options.forEach((element) => {
                  if (element.phone == undefined) {
                    element.phone = "暂无";
                  }
                });
              }
            });
        }, 1000);
      }
    },

    save() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.MyUtil.ajax()
            .post("/HeadUrlController/addHeadUrl", this.form)
            .then((resData) => {
              this.$Message.success("保存成功");
              this.getDataList();
              this.modal = false;
            });
        }
      });
    },
  },
  created() {
    this.getDataList();
  },
};
</script>
